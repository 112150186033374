import { Box } from "@mui/material";
import React from "react";
import PaymentFailedPage from "../../components/paymentFailed/PaymentFailedPage";

const PaymentFailed = () => {
	return (
		<Box>
			<PaymentFailedPage />
		</Box>
	);
};

export default PaymentFailed;
