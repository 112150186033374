import { Box } from "@mui/material";
import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const { deviceId, countryCode } = useParams();
  const location = useLocation();

  // const ioclCustomerPath = true;
  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  // Check if 'iocl' query parameter is true
  const ioclCustomerPath = queryParams.get("iocl") === "true";

  return (
    <Box
      className="page"
      sx={{
        width: "100%",
        padding: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fff",
      }}
      // onClick={() => navigate(`/${countryCode}/${deviceId}`)}
    >
      {ioclCustomerPath ? (
        <img style={{height: "40px"}} src="/images/ioclFinal.png" />
      ) : (
        <img style={{ width: "30px" }} src="/images/mainLogo.png" />
      )}
    </Box>
  );
};

export default Header;
