import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmAmountModal from "../Modals/ConfirmAmountModal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  fetchDeviceDetails,
  makePayment,
} from "../../redux/action/directPaymentSlice";
import { toast } from "sonner";
import { countryToCurrency } from "../../utils/countryCodes";
import { currencySymbols } from "../../utils/currencySymbols";

const values = ["50", "75", "100", "150", "250"];
const nzValues = ["1", "5", "10", "15", "20"];

const HomePage = () => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const { deviceId, countryCode } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { failedPayment, newAmount, retryTriggered, skipRetry } =
    location.state || {};
  const [retryLoading, setRetryLoading] = useState(false);

  console.log(
    "kkkkkkkkkkkkkkkkkkkk",
    failedPayment,
    newAmount,
    retryTriggered,
    skipRetry
  );

  const phoneNumber = location.state?.phoneNumber || "";

  // Extract the country code from the URL
  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2); // Assuming country codes are 2 letters
  const currency = countryToCurrency[countryCodeName] || "INR"; // Default to INR if not found
  const currencySymbol = currencySymbols[currency] || "₹"; // Default to ₹

  const amounts = currency === "NZD" ? nzValues : values;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChipClick = (value) => {
    setAmount(value ? Number(value) : "");
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setAmount(value ? Number(value) : "");
  };

  const deviceDetails = useSelector(
    (state) => state.directPayment.deviceDetails
  );

  useEffect(() => {
    if (deviceId) {
      dispatch(fetchDeviceDetails(deviceId));
    }
  }, [deviceId, dispatch]);

  const verifyOtpResponse = useSelector(
    (state) => state.directPayment.verifyOtpResponse
  );

  const saveToken = (token) => {
    const expiryTime = new Date().getTime() + 10 * 60 * 1000;
    localStorage.setItem("token", token);
    localStorage.setItem("tokenExpiry", expiryTime);
  };

  useEffect(() => {
    if (verifyOtpResponse?.token) {
      saveToken(verifyOtpResponse.token);
    }
  }, [verifyOtpResponse]);

  const handleProceed = () => {
    let minAmount;

    if (currency === "NZD") {
      minAmount = 0.5;
    } else if (currency === "LKR") {
      minAmount = 10;
    } else {
      minAmount = 20;
    }

    if (amount < minAmount) {
      toast.error(
        `Please enter a minimum amount of ${
          currency === "NZD"
            ? "NZD 0.50"
            : currency === "LKR"
            ? "LKR 10"
            : "₹20"
        }`
      );
    } else {
      handleOpen();
    }
  };

  const handleTax = () => {
    toast.info("Including Tax!");
  };

  useEffect(() => {
    if (retryTriggered && failedPayment === true && newAmount && !skipRetry) {
      setRetryLoading(true);

      dispatch(makePayment({ amount: newAmount, deviceId }))
        .then((res) => {
          if (res.error) {
            toast.error("Payment retry failed. Please try again.");
            setRetryLoading(false);
          } else {
            toast.success("Redirecting to Paytm...");
          }
        })
        .catch(() => {
          toast.error("Payment retry encountered an error.");
          setRetryLoading(false);
        });
    }
  }, [retryTriggered, failedPayment, newAmount, dispatch, deviceId, skipRetry]);

  return (
    <>
      {retryLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
          }}
        >
          <CircularProgress size={50} sx={{ color: "#FF7B52" }} />
        </Box>
      )}
      <Box className="page" sx={{ mt: "4px", background: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "6px",
          }}
        >
          <Typography
            color="#161616"
            fontSize={"14px"}
            fontFamily={"mySecondFont"}
            textAlign={"center"}
          >
            {deviceDetails?.deviceId || "N/A"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              {/* <img src="/images/ctype.png" alt="" /> */}
              <Typography
                color="#1C1C1C"
                fontFamily={"mySecondFont"}
                fontSize={"16px"}
              >
                {deviceDetails?.connectorType || "N/A"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                color="#2F2F2F"
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
              >
                Supply:
              </Typography>
              <Typography
                color="#1C1C1C"
                fontFamily={"mySecondFont"}
                fontSize={"16px"}
              >
                {`${deviceDetails?.power || "N/A"} kW`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                color="#2F2F2F"
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
              >
                Unit Cost:
              </Typography>
              <Typography
                color="#1C1C1C"
                fontFamily={"mySecondFont"}
                fontSize={"16px"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {`${currencySymbol}${deviceDetails?.unitCost || "N/A"}/kWh`}
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: 300,
                    marginLeft: "4px",
                  }}
                  className="material-symbols-outlined"
                  onClick={handleTax}
                >
                  info
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="page" sx={{ mt: "4px", background: "#fff" }}>
        <Box sx={{ padding: "6px" }}>
          <Typography
            textAlign={"center"}
            color={"#1C1C1C"}
            fontFamily={"'Montserrat', sans-serif"}
            fontWeight={600}
            fontSize={"18px"}
          >
            Please add charging amount
          </Typography>

          {/* Input */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              mt: "16px",
            }}
          >
            <Typography
              color="#1C1C1C"
              fontFamily={"mySecondFont"}
              fontSize={"16px"}
            >
              For how much amount do you want to charge your
              <br /> EV for?
            </Typography>
            <input
              style={{
                padding: "0px 16px",
                borderRadius: "8px",
                border: "1px solid #cbcbcb",
                height: "48px",
                outline: "none",
                fontFamily: "myThirdFont",
              }}
              placeholder="Please type in the amount here"
              type="number"
              value={amount} // Bind input value to state
              onChange={handleInputChange} // Handle input change
            />

            {/* value chips */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                mt: "12px",
                flexWrap: "wrap",
              }}
            >
              {amounts?.map((item, index) => {
                return (
                  <Box
                    key={index}
                    onClick={() => handleChipClick(item)}
                    sx={{
                      padding: "0 16px",
                      borderRadius: "40px",
                      background:
                        "linear-gradient(94deg, rgba(255, 123, 82, 0.15) -20.03%, rgba(229, 0, 0, 0.15) 122.12%)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      flexWrap: "nowrap",
                      height: "48px",
                      flexShrink: 0,
                    }}
                  >
                    <Typography
                      color="#000"
                      fontSize={"16px"}
                      fontFamily={"mySecondFont"}
                    >
                      {`${currency} ${item}`} {/* Use currency directly */}
                    </Typography>
                  </Box>
                );
              })}
            </Box>

            {/* Note and Button */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "12px",
                mb: "100px",
              }}
            >
              <Typography color="#727272" fontFamily={"myThirdFont"}>
                <span style={{ fontFamily: "mySecondFont" }}>Note:</span> The
                extra amount will be refunded back into payment source.
              </Typography>
              <Button
                sx={{
                  background:
                    "linear-gradient(94deg, #FF7B52 -20.04%, #E50000 122.31%)",
                  borderRadius: "8px",
                  padding: "12px 24px",
                  width: "100%",
                  fontFamily: "mySecondFont",
                  color: "#fcfcfc",
                  letterSpacing: "1.28px",
                }}
                onClick={handleProceed}
              >
                PROCEED TO ADD
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <ConfirmAmountModal
        open={open}
        handleClose={handleClose}
        amount={amount}
        deviceId={deviceId}
        countryCode={countryCode}
        phoneNumber={phoneNumber}
        deviceDetails={deviceDetails}
        setRetryLoading={setRetryLoading}
      />
    </>
  );
};

export default HomePage;
