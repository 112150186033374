import { Box, Container, Typography } from "@mui/material";
import React from "react";
import DeviceDetails from "../Cards/DeviceDetails";
import { useLocation } from "react-router-dom";

const MainPage = () => {
  const location = useLocation();

  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2);

  // const ioclCustomerPath = true;
  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  // Check if 'iocl' query parameter is true
  const ioclCustomerPath = queryParams.get("iocl") === "true";

  return (
    <Box className="page" sx={{ mt: "4px", background: "#fff" }}>
      <Container>
        {/* Top - Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img style={{ width: "32px" }} src="/images/bolt.png" alt="" />
          <Typography
            fontSize={"32px"}
            fontFamily={"'Montserrat', sans-serif"}
            fontWeight={700}
            sx={{
              background: "linear-gradient(95deg, #FF7B52 5.17%, #E50000 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {ioclCustomerPath ? "Welcome to IOCL" : "Welcome to 1C"}
          </Typography>
          <Typography
            mt={"4px"}
            color="#292D33"
            fontFamily={"'Montserrat', sans-serif"}
            letterSpacing={"1.12px"}
            fontWeight={600}
            fontSize={"14px"}
          >
            SCAN. PAY. CHARGE.
          </Typography>
          {countryCodeName === "IN" ? (
            <Box sx={{ mt: "8px" }}>
              <img
                style={{ maxWidth: "150px", width: "100%" }}
                src="/images/upi.png"
                alt=""
              />
            </Box>
          ) : (
            <Box sx={{ mt: "8px" }}>
              <img
                style={{ maxWidth: "150px", width: "100%" }}
                src="/images/stripe.png"
                alt=""
              />
            </Box>
          )}
        </Box>

        {/* Device - Detail - Card */}
        <Box sx={{ marginTop: "32px" }}>
          <DeviceDetails />
        </Box>
      </Container>
    </Box>
  );
};

export default MainPage;
