import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendOTP } from "../../redux/action/directPaymentSlice";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "sonner";

const PhoneVerify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState(""); // State for phone number input
  const [error, setError] = useState(""); // State for error message
  const { deviceId, countryCode } = useParams();
  const [name, setName] = useState(""); // State for the name input
  const location = useLocation();

  const sendOtpStatus = useSelector(
    (state) => state.directPayment.sendOtpStatus
  );
  const otpError = useSelector((state) => state.directPayment.error);

  // const ioclCustomerPath = true;
  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  // Check if 'iocl' query parameter is true
  const ioclCustomerPath = queryParams.get("iocl") === "true";

  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2);

  const handleVerify = () => {
    // Clear the error if the validation passes
    setError("");

    const formattedPhoneNumber = phoneNumber;

    const phoneNumberObj = parsePhoneNumber(formattedPhoneNumber);
    if (!phoneNumberObj || !phoneNumberObj.isValid()) {
      toast.error("Invalid phone number");
      return;
    }

    if (countryCodeName === "NZ") {
      // Navigate directly to the home page for NZ users
      navigate(`/home/${countryCode}/${deviceId}`, {
        state: { phoneNumber: formattedPhoneNumber },
      });
    } else {
      // Dispatch the sendOTP action with the phone number
      dispatch(sendOTP(formattedPhoneNumber))
        .unwrap() // Unwrap the promise to handle the result/error inline
        .then(() => {
          if (ioclCustomerPath) {
            // Navigate with 'iocl=true' if the condition is true
            navigate(
              `/otp-verify/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`,
              {
                state: { phoneNumber: formattedPhoneNumber, name: name },
              }
            );
          } else {
            // Navigate without 'iocl=true' if the condition is false
            navigate(`/otp-verify/${countryCode}/${deviceId}`, {
              state: { phoneNumber: formattedPhoneNumber, name: name },
            });
          }
        })
        .catch((err) => {
          console.error("Failed to send OTP:", err); // Handle any error in dispatch
          setError("Failed to send OTP. Please try again.");
        });
    }
  };

  const handleInputChange = (value) => {
    setPhoneNumber(value);
  };

  // Function to handle form submission on pressing "Enter" key
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleVerify(); // Call the verify function when Enter is pressed
    }
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  return (
    <Box
      className="page"
      sx={{ mt: "4px", background: "#fff", padding: "16px" }}
    >
      <Box
        sx={{
          mt: "22px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <Typography
          color="#292D33"
          fontFamily={"'Montserrat', sans-serif"}
          letterSpacing={"1.12px"}
          fontWeight={600}
          fontSize={"14px"}
        >
          SCAN. PAY. CHARGE.
        </Typography>
        <Box sx={{ mt: "8px" }}>
          {countryCodeName === "IN" ? (
            <Box sx={{ mt: "8px" }}>
              <img
                style={{ maxWidth: "150px", width: "100%" }}
                src="/images/upi.png"
                alt=""
              />
            </Box>
          ) : (
            <Box sx={{ mt: "8px" }}>
              <img
                style={{ maxWidth: "150px", width: "100%" }}
                src="/images/stripe.png"
                alt=""
              />
            </Box>
          )}
        </Box>
      </Box>

      {/* Form */}
      <Typography
        textAlign={"center"}
        color={"#1C1C1C"}
        fontFamily={"'Montserrat', sans-serif"}
        fontWeight={600}
        fontSize={"18px"}
        mt={"48px"}
      >
        Please add your
        <br />
        {ioclCustomerPath ? "name and phone number to continue": "phone number to continue"}
        
      </Typography>

      {ioclCustomerPath && (
        <Box
          sx={{
            mt: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography
            color="#2F2F2F"
            fontSize={"16px"}
            fontFamily={"mySecondFont"}
          >
            Name
          </Typography>
          <input
            style={{
              padding: "0px 16px",
              borderRadius: "8px",
              border: "1px solid #cbcbcb",
              height: "48px",
              outline: "none",
              fontFamily: "mySecondFont",
              fontSize: "16px",
              color: "#2F2F2F",
            }}
            placeholder="Please enter your name here"
            type="text"
            value={name}
            onChange={handleNameChange}
          />
        </Box>
      )}

      <Box
        sx={{
          mt: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Typography
          color="#2F2F2F"
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
        >
          Phone Number
        </Typography>
        {/* <input
          style={{
            padding: "0px 16px",
            borderRadius: "8px",
            border: "1px solid #cbcbcb",
            height: "48px",
            outline: "none",
            fontFamily: "mySecondFont",
            fontSize: "16px",
            color: "#2F2F2F",
          }}
          placeholder="Please enter your number here"
          type="tel"
          value={phoneNumber}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        /> */}

        <PhoneInput
          international
          defaultCountry={countryCodeName === "IN" ? "IN" : "NZ"}
          className={"input-phone-number"}
          value={phoneNumber}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder="Please enter your number here"
          style={{
            color: "#2F2F2F",
            fontFamily: "mySecondFont",
            fontSize: "16px",
            position: "relative",
            width: "100%",
            height: "48px",
            borderRadius: "8px",
            border: "1px solid #cbcbcb",
            padding: "0 12px",
            boxSizing: "border-box",
            backgroundColor: "#fff",
          }}
        />

        {/* Error message */}
        {error && (
          <Typography color="red" fontSize={"14px"} mt={"2px"} textAlign="end">
            {error}
          </Typography>
        )}
        {/* Redux OTP Error (optional) */}
        {otpError && typeof otpError === "string" && (
          <Typography color="red" fontSize={"14px"} mt={"2px"} textAlign="end">
            {otpError}
          </Typography>
        )}
        <Button
          sx={{
            mt: "16px",
            background: "#222",
            borderRadius: "8px",
            padding: "12px 24px",
            width: "100%",
            fontFamily: "mySecondFont",
            color: "#fcfcfc",
            letterSpacing: "1.28px",
            fontSize: "16px",
            mb: "214px",
          }}
          onClick={handleVerify}
        >
          {sendOtpStatus === "loading"
            ? "SENDING OTP..."
            : countryCodeName !== "IN"
            ? "PROCEED"
            : "GET OTP"}
        </Button>
      </Box>
    </Box>
  );
};

export default PhoneVerify;
