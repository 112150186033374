import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import { useLocation, useParams } from "react-router-dom";
import { fetchDeviceDetails } from "../../redux/action/directPaymentSlice";
import { useDispatch, useSelector } from "react-redux";
import { countryToCurrency } from "../../utils/countryCodes";
import { currencySymbols } from "../../utils/currencySymbols";

const DetailBox = ({ label, value, children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography fontSize="14px" fontFamily="myThirdFont" color="#2F2F2F">
      {label}
    </Typography>
    <Typography
      fontSize="18px"
      fontFamily="mySecondFont"
      color="#1C1C1C"
      sx={{ display: "flex", alignItems: "center" }}
    >
      {children || value}
    </Typography>
  </Box>
);

const ChargingStartPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { deviceId } = useParams();



  const deviceDetails = useSelector(
    (state) => state.directPayment.deviceDetails
  );

  useEffect(() => {
    if (deviceId) {
      dispatch(fetchDeviceDetails(deviceId)); // Fetch device details on component mount
    }
  }, [deviceId, dispatch]);

  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2); // Assuming country codes are 2 letters
  const currency = countryToCurrency[countryCodeName] || "INR"; // Default to INR if not found
  const currencySymbol = currencySymbols[currency] || "₹"; // Default to ₹

    // const ioclCustomerPath = true;
  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  // Check if 'iocl' query parameter is true
  const ioclCustomerPath = queryParams.get("iocl") === "true";
  const [expanded, setExpanded] = useState(ioclCustomerPath); // Accordion opens by default

  return (
    <>
      <Box className="page" sx={{ mt: "4px", background: "#fff" }}>
        <Accordion sx={{ boxShadow: "none" }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary
            expandIcon={<ArrowDropDownCircleOutlinedIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <img src="/images/done.png" alt="" />
              <Typography
                color="#00733D"
                fontSize={"20px"}
                fontWeight={700}
                fontFamily={"'Montserrat', sans-serif"}
              >
                Payment Successful
                <br />
                <span style={{ fontSize: "12px" }}>
                  Charging will be starting soon
                </span>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                borderRadius: "8px",
                background: "linear-gradient(180deg, #E9E9E9 0%, #F8F8F8 100%)",
                padding: "24px 16px",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              {/* <Box
                sx={{
                  display: "flex",
                  gap: "32px",
                  alignItems: "center",
                }}
              >
                <DetailBox label="Session ID" value="hfghskjk454gdgg" />
                <DetailBox label="Start time" value="10:25 PM" />
              </Box> */}

              <Box>
                <DetailBox
                  label="Device ID"
                  value={deviceDetails?.deviceId || "N/A"}
                />
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
                <DetailBox
                  label="Charger type"
                  value={deviceDetails?.chargingPointType || "N/A"}
                />
                <DetailBox
                  label="Connector type"
                  value={deviceDetails?.connectorType || "N/A"}
                />
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: "68px" }}>
                <DetailBox
                  label="Supply"
                  value={
                    deviceDetails?.power ? `${deviceDetails.power} kW` : "N/A"
                  }
                />
                <DetailBox
                  label="Unit Cost"
                  value={
                    deviceDetails?.unitCost
                      ? `${currencySymbol}${deviceDetails.unitCost}/kWh`
                      : "N/A"
                  }
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      
      {countryCodeName == "IN" && !ioclCustomerPath &&(
        <Box className="page" sx={{ mt: "4px", background: "#fff" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              mt={"14px"}
              color="#292D33"
              fontFamily={"'Montserrat', sans-serif"}
              letterSpacing={"1.28px"}
              fontWeight={600}
              fontSize={"16px"}
            >
              SEARCH. SCAN. CHARGE.
            </Typography>
            <img style={{ width: "240px" }} src="/images/phone2.png" alt="" />
          </Box>
          <Box
            sx={{
              background:
                "linear-gradient(100deg, #FF7B52 -34.07%, #E50000 104.51%), #292D33",
              padding: "24px 16px",
              marginLeft: "-10px",
              marginRight: "-10px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Typography
              fontSize={"24px"}
              fontFamily={"'Montserrat', sans-serif"}
              textAlign={"center"}
              fontWeight={700}
              color="#fff"
              textTransform={"capitalize"}
            >
              Download 1C App to view
              <br /> Live Charging Session
              <br /> Details and stats
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography
                textAlign={"center"}
                color="#fcfcfc"
                fontFamily={"mySecondFont"}
                fontSize={"16px"}
              >
                Download from
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <a
                  href="https://apps.apple.com/in/app/1c-ev-charging/id6478754214"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "100%" }}
                >
                  <Button
                    sx={{
                      padding: "14px 12px",
                      background: "#090909",
                      borderRadius: "8px",
                      width: "100%",
                    }}
                  >
                    <img
                      style={{ width: "126px", height: "30px" }}
                      src="/images/appstore.png"
                      alt=""
                    />
                  </Button>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=in.one.charging&hl=en_IN"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: "100%" }}
                >
                  <Button
                    sx={{
                      padding: "14px 12px",
                      background: "#090909",
                      width: "100%",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      style={{ width: "126px", height: "30px" }}
                      src="/images/playstore.png"
                      alt=""
                    />
                  </Button>
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ChargingStartPage;
