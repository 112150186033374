import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchDeviceDetails } from "../../redux/action/directPaymentSlice";
import { useDispatch, useSelector } from "react-redux";
import { countryToCurrency } from "../../utils/countryCodes";
import { currencySymbols } from "../../utils/currencySymbols";

const DetailBox = ({ label, value, children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography fontSize="14px" fontFamily="myThirdFont" color="#2F2F2F">
      {label}
    </Typography>
    <Typography
      fontSize="18px"
      fontFamily="mySecondFont"
      color="#1C1C1C"
      sx={{ display: "flex", alignItems: "center" }}
    >
      {children || value}
    </Typography>
  </Box>
);

const PaymentFailedPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { deviceId, countryCode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const shouldReload = !localStorage.getItem("hasReloaded");
    if (shouldReload) {
      localStorage.setItem("hasReloaded", "true");
      window.location.reload();
    }
  }, []);

  const deviceDetails = useSelector(
    (state) => state.directPayment.deviceDetails
  );

  // Extract data from state
  const { amount } = location.state || {}; // Extract amount from state

  useEffect(() => {
    if (deviceId) {
      dispatch(fetchDeviceDetails(deviceId)); // Fetch device details on component mount
    }
  }, [deviceId, dispatch]);

  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2); // Assuming country codes are 2 letters
  const currency = countryToCurrency[countryCodeName] || "INR"; // Default to INR if not found
  const currencySymbol = currencySymbols[currency] || "₹"; // Default to ₹

  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  const [expanded, setExpanded] = useState(true); // Accordion opens by default

  const handleBack = () => {
    localStorage.removeItem("hasReloaded"); // Clear the reload flag when navigating away
    navigate(`/home/${countryCode}/${deviceId}`, {
      state: { skipRetry: true },
    });
  };

  const handleRetryPayment = () => {
    localStorage.removeItem("hasReloaded"); // Clear the reload flag when navigating away
    navigate(`/home/${countryCode}/${deviceId}`, {
      state: { failedPayment: true, newAmount: amount, retryTriggered: true },
    });
  };

  return (
    <>
      <Box className="page" sx={{ mt: "4px", background: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center", // Ensures text is centered
            width: "100%", // Ensures it takes full width inside AccordionSummary
          }}
        >
          <img src="/images/failedpay.png" alt="Payment Failed" />
          <Typography
            color={"#790000"}
            fontSize={"24px"}
            fontFamily="'Montserrat', sans-serif"
            fontWeight={600}
            textAlign={"center"} // Ensure text alignment is centered
            textTransform={"capitalize"}
            sx={{ textWrap: "nowrap" }}
          >
            Payment Failed!
          </Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography
            color={"#2F2F2F"}
            fontSize={"18px"}
            fontFamily={"myThirdFont"}
            textAlign={"start"}
            textTransform={"capitalize"}
            sx={{ textWrap: "nowrap" }}
          >
            Please don’t worry, click on try again to pay
          </Typography>
          <Button
            sx={{
              background: "#222",
              borderRadius: "8px",
              padding: "12px 24px",
              height: "48px",
              color: "#fff",
            }}
            onClick={handleRetryPayment}
          >
            Try again paying ₹{amount}
          </Button>
          <Button
            sx={{
              background: "#fff",
              borderRadius: "8px",
              padding: "12px 24px",
              height: "48px",
              color: "#222222",
              border: "1px solid rgba(34, 34, 34, 0.50);",
              fontFamily: "mysecondFont",
            }}
            onClick={handleBack}
          >
            No, I want to change the amount
          </Button>
        </Box>
        <Box
          sx={{
            borderRadius: "8px",
            background: "linear-gradient(180deg, #E9E9E9 0%, #F8F8F8 100%)",
            padding: "24px 16px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            mt: "24px",
          }}
        >
          <Box>
            <DetailBox
              label="Device ID"
              value={deviceDetails?.deviceId || "N/A"}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
            <DetailBox
              label="Charger type"
              value={deviceDetails?.chargingPointType || "N/A"}
            />
            <DetailBox
              label="Connector type"
              value={deviceDetails?.connectorType || "N/A"}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "68px" }}>
            <DetailBox
              label="Supply"
              value={deviceDetails?.power ? `${deviceDetails.power} kW` : "N/A"}
            />
            <DetailBox
              label="Unit Cost"
              value={
                deviceDetails?.unitCost
                  ? `${currencySymbol}${deviceDetails.unitCost}/kWh`
                  : "N/A"
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PaymentFailedPage;
